<template>
  <EuiGridRow v-if="Signbook">
    <AddOrganizationsDialog :open.sync="isOrganizationsDialogOpened"
                            :selected="Signbook.recipientOrganizations"
                            @change="addOrganizations"/>
    <EuiGridColumn width="2/3">
      <EuiBlock :title="$t('paraphers.create.addParameters.globalParameters.title')">
        <ValidationProvider :name="$t('paraphers.create.addParameters.globalParameters.titleLabel')" rules="required|title" v-slot="{ errors, valid, invalid }">
          <EuiTextField :label="$t('paraphers.create.addParameters.globalParameters.titleLabel')"
                        type="text"
                        adaptSize
                        v-model="Signbook.name"
                        :valid="valid"
                        :invalid="errors.length > 0"
                        :warning="invalid && errors.length === 0"
                        :errorMessage="errors[0]"/>
        </ValidationProvider>
        <EuiHeading :level="3" bold>
          {{ $t('paraphers.create.addParameters.globalParameters.closingParameters') }}
        </EuiHeading>
        <div class="eui-u-flex">
          <ValidationProvider :name="$t('paraphers.create.addParameters.globalParameters.endingDate')" rules="required" v-slot="{ errors, valid, invalid }">
            <EuiTextField class="eui-u-w-auto eui-u-mr-5"
              :label="$t('paraphers.create.addParameters.globalParameters.endingDate')"
              type="date"
              v-model="Signbook.endingDate"
              :min="today"
              :valid="valid"
              :invalid="errors.length > 0"
              :warning="invalid && errors.length === 0"
              :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider slim
                              class="eui-u-flex-1"
                              :name="$t('paraphers.create.addParameters.globalParameters.notificationEmail')"
                              rules="required|email"
                              v-slot="{ errors, valid, invalid }">
            <EuiTextField :label="$t('paraphers.create.addParameters.globalParameters.notificationEmail')"
                          type="email"
                          adaptSize
                          v-model.trim="Signbook.notificationEmail"
                          :valid="valid"
                          :invalid="errors.length > 0"
                          :warning="invalid && errors.length === 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
        </div>
        <EuiHeading :level="3" bold>
          {{ $t('paraphers.create.addParameters.globalParameters.reminders') }}
        </EuiHeading>
        <div class="eui-u-flex">
          <ValidationProvider :name="$t('paraphers.create.addParameters.globalParameters.frequency')" rules="required" v-slot="{ errors, valid, invalid }">
            <EuiTextField class="eui-u-w-auto eui-u-mr-5"
              :label="$t('paraphers.create.addParameters.globalParameters.frequency')"
              type="number"
              min="1" max="999"
              v-model.number="Signbook.alertConfiguration.frequency"
              :valid="valid"
              :invalid="errors.length > 0"
              :warning="invalid && errors.length === 0"
              :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider :name="$t('paraphers.create.addParameters.globalParameters.interval')" rules="required" v-slot="{ errors, valid, invalid }">
            <EuiTextField class="eui-u-w-auto"
              :label="$t('paraphers.create.addParameters.globalParameters.interval')"
              type="number"
              min="1" max="999"
              v-model.number="Signbook.alertConfiguration.intervall"
              :valid="valid"
              :invalid="errors.length > 0"
              :warning="invalid && errors.length === 0"
              :errorMessage="errors[0]"/>
          </ValidationProvider>
        </div>
      </EuiBlock>
    </EuiGridColumn>
    <EuiGridColumn width="1/3">
      <EuiBlock v-if="can('notifyThirdParties', true)"
        :title="$t('paraphers.create.addParameters.thirdPartyEmails.title')" class="eui-u-mb-4 eui-u-flex-grow-0">
        <ValidationProvider ref="thirdPartyEmailVP" name="thirdPartyEmail" rules="email" v-slot="{ errors }">
         <EuiTextField  adaptSize
                        :errorMessage="$t('paraphers.create.addParameters.thirdPartyEmails.error')"
                        :invalid="errors.length > 0">
           <EuiLabel>{{ $t('paraphers.create.addParameters.thirdPartyEmails.label') }}</EuiLabel>
           <EuiSearchBar :placeholder="$t('cardFilter.searchBar.placeholder')"
                         v-model="thirdPartyEmail"
                         clearOnSearch
                         @change="addThirdPartyEmail(thirdPartyEmail)"
                         @search="addThirdPartyEmail(thirdPartyEmail)"
                         clearable
                         :suggestionsTitle="$t('paraphers.create.addParameters.thirdPartyEmails.suggestionsTitle')"
                         :buttonText="$t('button.add')"/>
         </EuiTextField>
        </ValidationProvider>
        <EuiList>
          <EuiListItem v-for="item in Signbook.thirdPartiesEmails" :key="item">
            <template #graphic>
              <EuiIcon name="person"/>
            </template>
            {{ item }}
            <template #meta>
              <EuiButton iconOnly color="primary" @click="removeThirdPartyEmail(item)">
                <EuiIcon name="delete"/>
              </EuiButton>
            </template>
          </EuiListItem>
        </EuiList>
      </EuiBlock>
      <EuiBlock v-if="can('selectCustomerOrgsArchival', true)"
                :title="$t('paraphers.create.addDocuments.archive.title')"
                class="eui-u-mb-4">
        <EuiHeading :level="4" bold class="eui-u-mt-3 eui-u-text-blue-grey">
          {{ isDeskRhDomain ? $t('paraphers.create.addDocuments.archive.deskrh') : $t('paraphers.create.addDocuments.archive.archiveDescription') }}
        </EuiHeading>
        <EuiButton variant="raised" size="small" color="primary" @click.prevent="isOrganizationsDialogOpened = true">
          {{ $t('button.select') }}
        </EuiButton>
        <ValidationProvider ref="organizations" name="organizations" :rules="{ required: isDeskRhDomain }" v-slot="{ errors }" mode="eager">
          <p v-if="errors[0]"
             class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
            {{ $t('paraphers.create.addSignatories.error.organizationSelectionRequired') }}
          </p>
          <EuiList class="eui-u-mt-3" v-model="Signbook.recipientOrganizations">
            <EuiListItem v-for="item in Signbook.recipientOrganizations" :key="item.organizationId">
              <template #graphic>
                <EuiIcon class="eui-u-text-blue-grey" name="bulding"/>
              </template>
              {{ item.issuerName }}
              <template #meta>
                <EuiButton iconOnly color="primary" @click="removeOrganization(item)">
                  <EuiIcon name="delete"/>
                </EuiButton>
              </template>
            </EuiListItem>
          </EuiList>
        </ValidationProvider>
      </EuiBlock>
      <EuiBlock :title="$t('paraphers.create.addParameters.generalMessage.title')" class="eui-u-flex-grow-0">
        <EuiLabel>{{ $t('paraphers.create.addParameters.generalMessage.label') }}</EuiLabel>
        <EuiTextarea fullWidth :rows="4" v-model.trim="Signbook.globalMessage" />
      </EuiBlock>
    </EuiGridColumn>
  </EuiGridRow>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import AddOrganizationsDialog from '../dialogs/AddOrganizationsDialog';
import mixin from '../../../mixins/compare';

export default {
  name: 'CreateParapherAddParameters',
  inject: ['Signbook'],
  mixins: [mixin],
  beforeRouteLeave(to, from, next) {
    if (this.$refs.thirdPartyEmailVP) {
      this.addThirdPartyEmail(this.thirdPartyEmail);
      this.$refs.thirdPartyEmailVP.validate();
    }
    next();
  },
  components: {
    AddOrganizationsDialog,
  },
  computed: {
    ...mapState({
      routeName: (state) => state.route.name,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
      can: 'ModuleEdocSign/auth/can',
    }),
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    user(value) {
      if (value.email) {
        this.Signbook.notificationEmail = value.email;
      }
    },
  },
  data() {
    return {
      thirdPartyEmail: '',
      isOrganizationsDialogOpened: false,
    };
  },
  methods: {
    async addThirdPartyEmail(thirdPartyEmail) {
      if (thirdPartyEmail.length !== 0) {
        // this.$nextTick(async () => {
        if (this.Signbook.thirdPartiesEmails.includes(thirdPartyEmail)) {
          return false;
        }
        const success = await this.$refs.thirdPartyEmailVP.validate();
        if (!success.valid) {
          return false;
        }
        this.Signbook.thirdPartiesEmails.push(thirdPartyEmail);
        this.thirdPartyEmail = '';
        return true;
        // });
      }
    },
    removeThirdPartyEmail(thirdPartyEmail) {
      this.Signbook.thirdPartiesEmails = this.Signbook.thirdPartiesEmails.filter((element) => element !== thirdPartyEmail);
    },
    removeOrganization(organization) {
      this.Signbook.recipientOrganizations = this.Signbook.recipientOrganizations.filter((element) => this.compare(element, organization) === false);
    },
    addOrganizations(organizations) {
      if (organizations) {
        this.Signbook.recipientOrganizations = organizations;
      }
    },
    resetForm() {
      this.Signbook.name = '';
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
  mounted() {
    if (this.user !== undefined && this.Signbook.notificationEmail === '') {
      this.Signbook.notificationEmail = this.user.email;
    }
  },
};
</script>
