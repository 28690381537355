<template>
  <EuiDialog size="large" :open="open" @onClose="$emit('update:open', false)">
    <template #header>
      {{ $t('paraphers.dialogs.addOrganizations.title') }}
    </template>
    <EuiGrid>
      <EuiGridRow>
        <EuiGridColumn>
         <span v-if="selectedOrganizations && selectedOrganizations.length > 0" class="font-bold">{{ selectedOrganizations.length }} organisations</span>
        </EuiGridColumn>
        <EuiGridColumn width="1/3">
          <EuiSearchBar :placeholder="$t('searchBar.placeholder')"
                        v-model.trim="searchInput"
                        @change="searchOrganizations"
                        @clear="onClear"
                        clearable
          />
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
    <div v-show="this.filters.length > 0">
      <EuiDivider secondary class="m-1"/>
      <div class="eui-u-flex eui-u-items-center eui-u-ml-6">
        <EuiLabel>{{ $t('filterBar.title') }}</EuiLabel>
        <EuiIcon name="filter" class="ml-2"></EuiIcon>
        <EuiChip v-for="filter in filters"
                 closable
                 @click:close="removeFilter(filter)"
                 :key="filter.name"
                 class="eui-u-text-white eui-u-ml-4">
          <template #text>
            <span class="eui-u-font-bold eui-u-text-white">{{ $t(`filterBar.chips.title.${filter.name}`) }}:</span>
            <span class="eui-u-text-white">{{ filter.value }}</span>
          </template>
        </EuiChip>
        <EuiChip :text="$t('filterBar.button.reset')"
                 clickable outlined
                 class="eui-u-ml-4"
                 @click:chip="resetFilters"/>
      </div>
      <EuiDivider secondary class="m-1"/>
    </div>
    <EuiAdvancedTable v-model="organizations" :selectedValue.sync="selectedOrganizations">
      <template #tableHead>
        <!-- full name -->
        <EuiTableHead>
          {{ $t('paraphers.dialogs.addOrganizations.name') }}
          <EuiDropdownMenu ref="ddNameFilter" :closeOnClickInside="false">
            <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                           :buttonText="$t('cardFilter.button.filter')"
                           v-model="availableFilters.name.searchFieldValue"
                           @click:search="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                           @enter:searchBar="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                           @click:filter="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()">
            </EuiCardFilter>
            <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
          </EuiDropdownMenu>
        </EuiTableHead>
        <EuiTableHead>
          {{ $t('paraphers.dialogs.addOrganizations.siret') }}
          <EuiDropdownMenu ref="ddSiretFilter" :closeOnClickInside="false">
            <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                           :buttonText="$t('cardFilter.button.filter')"
                           v-model="availableFilters.siret.searchFieldValue"
                           @click:search="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()"
                           @enter:searchBar="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()"
                           @click:filter="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()">
            </EuiCardFilter>
            <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
          </EuiDropdownMenu>
        </EuiTableHead>
      </template>
      <EuiATableRow :selectable="isDeskRhDomain ? 'single' : 'multiple'" :autoSelect="!isDeskRhDomain" slot="tableRow" slot-scope="{ item }">
        <EuiTableCell>{{ item.issuerName }}</EuiTableCell>
        <EuiTableCell>{{ item.siret }}</EuiTableCell>
      </EuiATableRow>
      <template #tableEmptyState>
        <p class="es-subtitle">{{ $t('paraphers.dialogs.addOrganizations.error.noMatch') }}</p>
      </template>
    </EuiAdvancedTable>
    <template #footer>
      <EuiPagination v-if="organizations.length"
                     :value="pagination.current"
                     @change="goToPage"
                     :paginationText="$t('pagination.text')"
                     :totalPages="pagination.total"/>
      <EuiButton color="primary" variant="raised" @click="add">{{ $t('button.add') }}
      </EuiButton>
    </template>
  </EuiDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddOrganizationsDialog',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.selectedOrganizations = this.selected.slice(0);
        this.searchParams = {
          orders:
            [{ fieldName: 'name', orderBy: 'ASC' }],
          params:
            [],
          offset: 0,
          limit: 20,
        };
        this.find();
      }
    },
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.organizations.pagination,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
  },
  data() {
    return {
      organizations: [],
      selectedOrganizations: [],
      searchInput: '',
      availableFilters: {
        name: {
          type: 'text', name: 'name', value: '', searchParamName: 'name', searchFieldValue: '',
        },
        siret: {
          type: 'text', name: 'siret', value: '', searchParamName: 'siret', searchFieldValue: '',
        },
      },
      filters: [],
    };
  },
  methods: {
    ...mapActions({
      search: 'ModuleEdocSign/organizations/search',
    }),
    add() {
      this.$emit('change', Array.isArray(this.selectedOrganizations) ? this.selectedOrganizations : [this.selectedOrganizations]);
      this.$emit('update:open', false);
    },
    find() {
      this.search(this.searchParams)
        .then((response) => {
          this.organizations = response.organizations;
        });
    },
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.find();
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.find();
    },
    onClear() {
      this.searchInput = '';
      this.searchParams.params = [];
      this.find();
    },
    searchOrganizations() {
      this.searchParams.params = [];
      if (this.searchInput) {
        this.searchParams.params.push({
          paramName: 'name', paramValue: this.searchInput,
        });
      } else {
        this.filters.forEach((filter) => {
          this.searchParams.params.push({
            paramName: filter.name, paramValue: filter.value,
          });
        });
      }

      this.find();
    },
    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.addFilter(filter);
    },
    addFilter(filterToAdd) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToAdd.name);
      this.filters.push(filterToAdd);
      this.searchOrganizations();
    },
    removeFilter(filterToRemove) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToRemove.name);
      this.searchOrganizations();
    },
    resetFilters() {
      this.filters = [];
      this.searchOrganizations();
    },
  },
};
</script>
